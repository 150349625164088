<template>
  <div class="dengji">
    <div
      class="no-print"
      v-if="isinfo"
      style="text-align:right;background: #fff; margin: 0 10px;padding:20px 30px 0 0"
    >
      <!--<el-button
        size="small"
        icon="el-icon-back"
        style="float:left;margin-left:30px"
        @click="isinfo=!isinfo"
      >返回</el-button>!-->
      <el-button size="small" v-print="'#printPage'" type="primary" icon="el-icon-receiving">打印</el-button>
    </div>
    <div class="viewbox" style="background:#fff;margin:0px 10px;padding:20px 30px" id="printPage">
      <div class="baseDetail">
        <table cellspacing="0" cellpadding="0">
          <tr>
            <td colspan="7" style="text-align:center;font-weight:bold">应聘人员登记表</td>
          </tr>
          <tr>
            <td
              colspan="3"
              style="text-align:left;padding-left:20px;border-right-color: transparent;height:50px"
            >
              登记日期：
              <el-date-picker
                v-model="form.applyTime"
                v-show="!isinfo"
                clearable
                type="date"
                value-format="yyyy-MM-dd"
                size="small"
                placeholder="请选择面试时间"
              ></el-date-picker>
              <span v-show="isinfo">{{form.applyTime}}</span>
            </td>
            <td colspan="4" style="height:50px;text-align:right;padding-right:20px">
              应聘岗位：
              <el-input
                style="width:200px"
                size="small"
                v-model="form.applyPosition"
                v-show="!isinfo"
              ></el-input>
              <span v-show="isinfo">{{form.applyPosition}}</span>
            </td>
          </tr>
          <tr>
            <td :rowspan="6" style="text-align:center">个人资料</td>
            <td style="text-align:center">姓名</td>
            <td v-show="isinfo" style="text-align:center">{{form.name}}</td>
            <td v-show="!isinfo" class="write" style="text-align:left;padding:5px">
              <el-input v-model="form.name"></el-input>
            </td>
            <td style="text-align:center;">手机号码</td>
            <td v-show="isinfo" style="text-align:center">{{form.phoneNumber}}</td>
            <td v-show="!isinfo" class="write" style="text-align:left;padding:5px">
              <el-input v-model="form.phoneNumber"></el-input>
            </td>
            <td style="text-align:center">性别</td>
            <td v-show="isinfo" style="text-align:center">{{form.sex}}</td>
            <td v-show="!isinfo" class="write" style="text-align:left;padding:5px">
              <el-input v-model="form.sex"></el-input>
            </td>
          </tr>
          <tr>
            <td style="text-align:center">出生日期</td>
            <td v-show="isinfo" style="text-align:center">{{form.birthday}}</td>
            <td v-show="!isinfo" class="write" style="text-align:left;padding:5px">
              <el-date-picker
                v-model="form.birthday"
                clearable
                type="date"
                value-format="yyyy-MM-dd"
                size="small"
                placeholder="请选择出生日期"
              ></el-date-picker>
            </td>
            <td style="text-align:center">最高学历</td>
            <td v-show="isinfo" style="text-align:center">{{form.highestSchooling}}</td>
            <td v-show="!isinfo" class="write" style="text-align:left;padding:5px">
              <el-input v-model="form.highestSchooling"></el-input>
            </td>
            <td style="text-align:center">婚育</td>
            <td v-show="isinfo" style="text-align:center">{{form.marital}}</td>
            <td v-show="!isinfo" class="write" style="text-align:left;padding:5px">
              <el-input v-model="form.marital"></el-input>
            </td>
          </tr>
          <tr>
            <td style="text-align:center">现住址</td>
            <td colspan="3" v-show="isinfo" style="text-align:center">{{form.currentAddress}}</td>
            <td colspan="3" class="write" v-show="!isinfo" style="text-align:left">
              <el-input v-model="form.currentAddress"></el-input>
            </td>
            <td style="text-align:center">籍贯</td>
            <td v-show="isinfo" style="text-align:center">{{form.nativePlace}}</td>
            <td v-show="!isinfo" class="write" style="text-align:left;padding:5px">
              <el-input v-model="form.nativePlace"></el-input>
            </td>
          </tr>
          <tr>
            <td style="text-align:center">身份证号码</td>
            <td colspan="2" v-show="isinfo" style="text-align:center">{{form.idCardNumber}}</td>
            <td colspan="2" class="write" v-show="!isinfo" style="text-align:left">
              <el-input v-model="form.idCardNumber"></el-input>
            </td>
            <td style="text-align:center">邮箱</td>
            <td v-show="isinfo" colspan="2" style="text-align:center">{{form.email}}</td>
            <td v-show="!isinfo" class="write" colspan="2" style="text-align:left;padding:5px">
              <el-input v-model="form.email"></el-input>
            </td>
          </tr>
          <tr>
            <td style="text-align:center;">户籍所在地</td>
            <td v-show="isinfo" style="text-align:center">{{form.registerAddress}}</td>
            <td v-show="!isinfo" class="write" style="text-align:left;padding:5px">
              <el-input v-model="form.registerAddress"></el-input>
            </td>
            <td style="text-align:center;">预计到岗时间</td>
            <td v-show="isinfo" style="text-align:center">{{form.arrivalTime}}</td>
            <td v-show="!isinfo" class="write" style="text-align:left;padding:5px">
              <el-date-picker
                v-model="form.arrivalTime"
                clearable
                type="date"
                value-format="yyyy-MM-dd"
                size="small"
                placeholder="请选择预计到岗时间"
              ></el-date-picker>
            </td>
            <td style="text-align:center;">政治面貌</td>
            <td v-show="isinfo" style="text-align:center">{{form.political}}</td>
            <td v-show="!isinfo" class="write" style="text-align:left;padding:5px">
              <el-input v-model="form.political"></el-input>
            </td>
          </tr>
          <tr>
            <td style="text-align:center;">职称</td>
            <td v-show="isinfo" style="text-align:center">{{form.positionTitle}}</td>
            <td v-show="!isinfo" class="write" style="text-align:left;padding:5px">
              <el-input v-model="form.positionTitle"></el-input>
            </td>
            <td style="text-align:center;">执业资格</td>
            <td v-show="isinfo" style="text-align:center">{{form.professionalQualification}}</td>
            <td v-show="!isinfo" class="write" style="text-align:left;padding:5px">
              <el-input v-model="form.professionalQualification"></el-input>
            </td>
            <td style="text-align:center;">执业资格编号</td>
            <td v-show="isinfo" style="text-align:center">{{form.serialNumbe}}</td>
            <td v-show="!isinfo" class="write" style="text-align:left;padding:5px">
              <el-input v-model="form.serialNumbe"></el-input>
            </td>
          </tr>
          <tr>
            <td :rowspan="4" style="text-align:center">教育经历</td>
            <td style="text-align:center">起止时间</td>
            <td :colspan="2" style="text-align:center">学校名称</td>
            <td :colspan="2" style="text-align:center">专业</td>
            <td style="text-align:center">学历/学位</td>
          </tr>
          <template v-if="isinfo">
            <tr v-for="(education,i) in form.educationRequestList" :key="'education'+i">
              <td style="text-align:center">{{education.startTime}}-{{education.endTime}}</td>
              <td :colspan="2" style="text-align:center">{{education.schoolName}}</td>
              <td :colspan="2" style="text-align:center">{{education.profession}}</td>
              <td style="text-align:center">{{education.formal}}</td>
            </tr>
          </template>
          <template v-else>
            <tr v-for="(education,i) in form.educationRequestList" :key="'education'+i">
              <td style="text-align:center" class="write">
                <el-date-picker
                  v-model="education.timeArr"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                ></el-date-picker>
              </td>
              <td :colspan="2" class="write" style="text-align:left;padding:5px">
                <el-input v-model="education.schoolName"></el-input>
              </td>
              <td :colspan="2" class="write" style="text-align:left;padding:5px">
                <el-input v-model="education.profession"></el-input>
              </td>
              <td class="write" style="text-align:left;padding:5px">
                <el-input v-model="education.formal"></el-input>
              </td>
            </tr>
          </template>
          <tr>
            <td :rowspan="3" style="text-align:center">培训经历</td>
            <td style="text-align:center">起止时间</td>
            <td :colspan="2" style="text-align:center">培训机构</td>
            <td :colspan="2" style="text-align:center">课程</td>
            <td style="text-align:center">所获证书</td>
          </tr>
          <template v-if="isinfo">
            <tr v-for="(training,i1) in form.trainingRequests" :key="'training'+i1">
              <td style="text-align:center">{{training.startTime}}-{{training.endTime}}</td>
              <td :colspan="2" style="text-align:center">{{training.trainingName}}</td>
              <td :colspan="2" style="text-align:center">{{training.course}}</td>
              <td style="text-align:center">{{training.certificate}}</td>
            </tr>
          </template>
          <template v-else>
            <tr v-for="(training,i1) in form.trainingRequests" :key="'training'+i1">
              <td style="text-align:center" class="write">
                <el-date-picker
                  v-model="training.timeArr"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                ></el-date-picker>
              </td>
              <td :colspan="2" class="write" style="text-align:left;padding:5px">
                <el-input v-model="training.trainingName"></el-input>
              </td>
              <td :colspan="2" class="write" style="text-align:left;padding:5px">
                <el-input v-model="training.course"></el-input>
              </td>
              <td class="write" style="text-align:left;padding:5px">
                <el-input v-model="training.certificate"></el-input>
              </td>
            </tr>
          </template>
          <tr>
            <td :rowspan="5" style="text-align:center">工作经历</td>
            <td style="text-align:center">起止时间</td>
            <td :colspan="2" style="text-align:center">单位名称</td>
            <td style="text-align:center">职位</td>
            <td style="text-align:center">薪酬情况</td>
            <td style="text-align:center">证明人/电话</td>
          </tr>
          <template v-if="isinfo">
            <tr v-for="(work,i2) in form.workRequests" :key="'work'+i2">
              <td style="text-align:center">{{work.startTime}}-{{work.endTime}}</td>
              <td :colspan="2" style="text-align:center">{{work.unitName}}</td>
              <td style="text-align:center">{{work.position}}</td>
              <td style="text-align:center">{{work.compensation}}</td>
              <td style="text-align:center">{{work.references}}/{{work.referencesName}}</td>
            </tr>
          </template>
          <template v-else>
            <tr v-for="(work,i2) in form.workRequests" :key="'work'+i2">
              <td style="text-align:center" class="write">
                <el-date-picker
                  v-model="work.timeArr"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                ></el-date-picker>
              </td>
              <td :colspan="2" class="write" style="text-align:left;padding-left:5px">
                <el-input v-model="work.unitName"></el-input>
              </td>
              <td class="write" style="text-align:left;padding-left:5px">
                <el-input v-model="work.position"></el-input>
              </td>
              <td class="write" style="text-align:left;padding-left:5px">
                <el-input v-model="work.compensation"></el-input>
              </td>
              <td class="write" style="text-align:center">
                <el-input v-model="work.references"></el-input>/
                <el-input v-model="work.referencesName"></el-input>
              </td>
            </tr>
          </template>
          <template v-if="!isinfo">
            <tr>
              <td :rowspan="4" style="text-align:center">个人能力</td>
              <td style="text-align:center">语言</td>
              <td :colspan="5" class="write">
                <el-input v-model="form.language"></el-input>
              </td>
            </tr>
            <tr>
              <td style="text-align:center">计算机水平</td>
              <td :colspan="5" class="write">
                <el-input v-model="form.computerLevel"></el-input>
              </td>
            </tr>
            <tr>
              <td style="text-align:center">爱好</td>
              <td :colspan="5" class="write">
                <el-input v-model="form.hobby"></el-input>
              </td>
            </tr>
            <tr>
              <td style="text-align:center">特长</td>
              <td :colspan="5" class="write">
                <el-input v-model="form.specialty"></el-input>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td :rowspan="4" style="text-align:center">个人能力</td>
              <td style="text-align:center">语言</td>
              <td :colspan="5" style="text-align:center">{{form.language}}</td>
            </tr>
            <tr>
              <td style="text-align:center">计算机水平</td>
              <td :colspan="5" style="text-align:center">{{form.computerLevel}}</td>
            </tr>
            <tr>
              <td style="text-align:center">爱好</td>
              <td :colspan="5" style="text-align:center">{{form.hobby}}</td>
            </tr>
            <tr>
              <td style="text-align:center">特长</td>
              <td :colspan="5" style="text-align:center">{{form.specialty}}</td>
            </tr>
          </template>
          <tr>
            <td style="text-align:center">自我评价</td>
            <td v-if="!isinfo" :colspan="6" class="write">
              <el-input type="textarea" autosize v-model="form.selfAssessment"></el-input>
            </td>
            <td :colspan="6" v-else style="text-align:center">{{form.selfAssessment}}</td>
          </tr>
          <template v-if="!isinfo">
            <tr>
              <td style="text-align:center">薪资要求</td>
              <td class="write">
                <el-input v-model="form.salaryRequirements"></el-input>
              </td>
              <td style="text-align:center">紧急联系人</td>
              <td class="write">
                <el-input v-model="form.emergencyContact"></el-input>
              </td>
              <td style="text-align:center">紧急联系人电话</td>
              <td class="write" :colspan="2">
                <el-input v-model="form.emergencyContactPhone"></el-input>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td style="text-align:center">薪资要求</td>
              <td style="text-align:center">{{form.salaryRequirements}}</td>
              <td style="text-align:center">紧急联系人</td>
              <td style="text-align:center">{{form.emergencyContact}}</td>
              <td style="text-align:center">紧急联系人电话</td>
              <td style="text-align:center" colspan="2">{{form.emergencyContactPhone}}</td>
            </tr>
          </template>
          <tr>
            <td
              :colspan="7"
              style="text-align:left;padding-left:20px"
            >注：本人声明此表填写内容完全属实，如有虚假内容自愿接受无赔偿解聘。</td>
          </tr>
        </table>
      </div>
      <div style="margin-top:10px;text-align: center;" v-if="!isinfo">
        <el-button type="primary" @click="saveForm">提交</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { resumeApplyInfo, resumeApplySave } from '@/api/recruitment_allocation';

export default {
  data() {
    return {
      isinfo: false,
      form: {
        code: this.$route.query.code,
        applyTime: '2010-04-07',
        applyPosition: '开发工程师',
        name: '小飞',
        political: '党员',
        sex: '男',
        birthday: '1997-03-01',
        highestSchooling: '博士后',
        marital: '已婚',
        currentAddress: '珠江新城',
        nativePlace: '江西',
        idCardNumber: '440582199508062931',
        email: '944680455@qq.com',
        registerAddress: '广东',
        arrivalTime: '2020-05-01',
        phoneNumber: '13112944280',
        positionTitle: '无敌',
        professionalQualification: '没有',
        serialNumbe: '007',
        language: '精通各种语言',
        computerLevel: '12级',
        hobby: '无',
        specialty: '脚特长',
        selfAssessment: '你好',
        salaryRequirements: '20k',
        emergencyContact: '小豪',
        emergencyContactPhone: '185582233120',
        signature: '小小飞',
        educationRequestList: [
          { timeArr: [] },
          { timeArr: [] },
          { timeArr: [] }
        ],
        trainingRequests: [{timeArr: []}, {timeArr: []}],
        workRequests: [{timeArr: []}, {timeArr: []}, {timeArr: []}, {timeArr: []}]
      }
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.getRemuseInfo();
    }
  },
  methods: {
    getRemuseInfo() {
      resumeApplyInfo({ id: this.$route.params.id }).then((res) => {
        if (res.code == 0) {
          this.form = res;
          this.isinfo = true;
        }
      });
    },
    saveForm() {
      const educationRequestList = [];

      this.form.educationRequestList.map((it) => {
        if (it.timeArr.length > 0) {
          it.startTime = it.timeArr[0];
          it.endTime = it.timeArr[1];
          educationRequestList.push(it);
        }
      });
      const trainingRequests = [];

      this.form.trainingRequests.map((it) => {
        if (it.timeArr.length > 0) {
          it.startTime = it.timeArr[0];
          it.endTime = it.timeArr[1];
          trainingRequests.push(it);
        }
      });
      const workRequests = [];

      this.form.workRequests.map((it) => {
        if (it.timeArr.length > 0) {
          it.startTime = it.timeArr[0];
          it.endTime = it.timeArr[1];
          workRequests.push(it);
        }
      });
      resumeApplySave({...this.form, educationRequestList, trainingRequests, workRequests}).then((res) => {
        console.log(res);
      });
    }
  }
};
</script>
<style lang="scss">
.dengji {
  .write {
    .el-range-editor.el-input__inner,
    .el-textarea__inner,
    .el-input__inner {
      border: none;
    }
  }
}
</style>
<style lang="scss" scoped>
.baseDetail {
  display: flex;
  margin-top: 10px;
  table {
    width: 100%;
    min-width: 500px;
    border: 1px solid #b0bacb;
    :first-child {
      td:nth-child(even) {
        text-align: right;
        padding: 10px;
      }
      td:nth-child(odd) {
        background: #fff;
        text-align: left;
        padding: 10px;
      }
    }
    tr {
      line-height: 2.3;
      .pic {
        background: #ffffff !important;
        padding: 0px !important;
        width: 100%;
        img {
          width: 100%;
          height: 100%;
        }
        p {
          text-align: center;
          margin-bottom: 0;
          margin-top: 0;
        }
      }
    }
    td {
      border: 1px solid #b0bacb;
      vertical-align: middle;
    }
    td:nth-child(odd) {
      text-align: right;
      width: 14%;
    }
    td:nth-child(even) {
      text-align: left;
    }
  }
}
.details {
  display: flex;
  margin-top: 10px;
  .detailTitle {
    display: flex;
    // justify-conten:center;
    height: 30px;
    font-size: 10px;
    font-size: 18px;
  }
  div {
    width: calc(100% / 2);
  }
  div:first-child {
    margin-right: 20px;
  }
  table {
    width: 100%;
    height: 150px;
    border: 1px solid #b0bacb;
    thead {
      td {
        height: 50px;
      }
    }
    td {
      border: 1px solid #b0bacb;
      word-break: break-all;
      padding-left: 10px;
      vertical-align: middle;
    }
  }
}
.titleIcon {
  display: inline-block;
  width: 8px !important;
  height: 20px;
  background: #498df0;
  border-radius: 5px;
  vertical-align: bottom;
  margin-right: 5px !important;
}
</style>
